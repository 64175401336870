export const Apps = [
  {
    name: "DREI NIMMT",
    description:
      "Ein Spiel bei dem ihr euer Können unter Beweis stellen könnt.Siehst du drei Gleiche Farben nebeneinander tippe sie einfach an. Garnicht so leicht wie du vllt denkst.Verschiedene Upgrades und Booster machen das Spiel spannender und lustiger. Mit mehr als 1 Millionen Downloads ist unser Spiel ein sehr beliebtes Spiel. Probiere es noch heute aus und tippe oben auf den INSTALLIEREN Knopf.",
    kategorien: ["Puzzle", "Geschicklichtkeit", "Schnelligkeit"],
    icon: "Element 3.png",
    bild: "dreinimmt.png",
    sterne: 2.5,
    downloads: "1 Mio.",
    usk: 0,
    version: "1.23.56",
    anbieter: "BESTFUNGAMES",
    infos: ["In-App-Käufe möglich", "Werbung", "Verwendet APPStore-Pay"],
    berechtigungen: [
      "APPstore Profildaten",
      "Bank-Kontodaten",
      "Standort",
      "Kamera",
      "Handy-Daten",
      "Sonstiges",
    ],
    installiert: false,
    preis: 0,
    gekauft: true,
    link: "/spielDreiNimmt",
    data: undefined,
  },
  {
    name: "SUPER EPIC AUTORENNEN",
    description:
      "Super duper epic new AUTORENNEN jetzt verfügbar. Fahre Rennen und werde ERSTER. Hole dir deine Siege sammle Trophäen. Du magst Autos und Autorennen? Dann ist dies genau das richtige Spiel für dich. ",
    kategorien: ["Rennen", "Autos", "Schnelligkeit"],
    icon: "Element 4.png",
    bild: "superepichrennen.png",
    sterne: 4,
    downloads: "10 Mio.",
    usk: 6,
    version: "7.1",
    anbieter: "Alles-mit-Autos",
    infos: ["In-App-Käufe möglich", "Werbung", "Verwendet APPStore-Pay"],
    berechtigungen: [
      "APPstore Profildaten",
      "Bank-Kontodaten",
      "Standort",
      "Kamera",
      "Handy-Daten",
      "Sonstiges",
    ],
    installiert: false,
    preis: 0,
    gekauft: true,
    link: "/spielSuperEpicAutorennen",
    data: undefined,
  },
  /*{
    name: "!!NEU!! HAUSTIER-SPIEL",
    description:
      "Niedliche Tiere und ihre Kostüme werden dir im Haustier-Simulator viel Spaß bereiten. Gib deinen Tieren süße Verkleidungen oder lasse sie raus in den Garten damit sie sich vergnügen können. Wähle aus einer Vielzahl an Tieren (Hase, Katze, Pferd Hund). Extrem viele Verkleidungen mit vielen weiteren die noch kommen werden.",
    kategorien: ["Tiere", "Niedlich", "Entspannt"],
    icon: "Element 5.png",
    bild: "picture-20.jpg",
    sterne: 4,
    downloads: "500 Tsd.",
    usk: 0,
    version: "0.1.12",
    anbieter: "ChinGames.com",
    infos: ["In-App-Käufe möglich", "Werbung", "Verwendet APPStore-Pay"],
    berechtigungen: [
      "APPstore Profildaten",
      "Bank-Kontodaten",
      "Standort",
      "Kamera",
      "Handy-Daten",
      "Sonstiges",
    ],
    installiert: false,
    preis: 0,
    gekauft: true,
    link: undefined,
    data: undefined,
  },*/
  {
    name: "STEFFANO BRÜDER 2D DELUXE",
    description:
      "Steffano und seine Brüder müssen die niedliche Schildkröte mit dem Namen Fowser befreien. Die fiese Prinzessin und ihren Verbündeten halten sie in einem Turm gefangen. Kannst du es schaffen? Der Weg zum Turm ist schwierig und erfordert viel Mut. Stelle dich der Gefahr und rette Fowser.",
    kategorien: ["Jump´n Run", "Mehrspieler", "Lustig"],
    icon: "Element 6.png",
    bild: "steffanobrueder.png",
    sterne: 3.5,
    downloads: "6 Mio.",
    usk: 6,
    version: "4.2",
    anbieter: "ItalienVertrieb GmbH",
    infos: ["In-App-Käufe möglich", "Werbung", "Verwendet APPStore-Pay"],
    berechtigungen: [
      "APPstore Profildaten",
      "Bank-Kontodaten",
      "Standort",
      "Kamera",
      "Handy-Daten",
      "Sonstiges",
    ],
    installiert: false,
    preis: 0,
    gekauft: true,
    link: "/spielSteffanoBrueder",
    data: undefined,
  },
  {
    name: "KEKSE ESSEN WETTKAMPF",
    description:
      "Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse, Kekse und noch mehr Kekse werde zum Keksmeister!",
    kategorien: ["Klicker", "Idle Game", "Essen"],
    icon: "Element 8.png",
    bild: "kekseessen.png",
    sterne: 5,
    downloads: "50 Tsd.",
    usk: 6,
    version: "6.00.7",
    anbieter: "Krümelmonster & CO. KG.",
    infos: ["In-App-Käufe möglich", "OHNE Werbung", "Verwendet APPStore-Pay"],
    berechtigungen: [
      "APPstore Profildaten",
      "Bank-Kontodaten",
      "Standort",
      "Kamera",
      "Handy-Daten",
      "Sonstiges",
    ],
    installiert: false,
    preis: 2.99,
    gekauft: false,
    link: "/spielKekseEssen",
    data: undefined,
  },
];
